@import "variables";

.grecaptcha-badge {
  display: none !important;
}

.content {
  .ticklist {
    list-style: none;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;

    & > li {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 36px;
        height: 36px;
        background-image: url("/assets/tick_circle.webp");
      }
    }
  }

  .questionlist {
    list-style: none;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;

    & > li {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-weight: bold;
      flex-wrap: wrap;

      ul {
        width: 100%;
        font-weight: normal;
        padding-left: 4rem;
        list-style: disc;
      }

      &:before {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url("/assets/speak_circle.webp");
      }
    }
  }
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;

  &-track {
    border-radius: 0;
    background-color: #{$navy};
  }

  &-thumb {
    border-radius: 0;
    // background-color: #{$blue};
    background: $white;
    border: 1px solid #{$navy};
  }
}

.opacity-10 {
  opacity: 0.1;
}

#__next {
  min-height: 100vh;
  max-width: 100vw;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    a {
      color: #{$blue};
    }
  }

  main {
    flex-grow: 1;
  }

  // utility

  .opacity-60 {
    opacity: 0.6;
  }

  .opacity-40 {
    opacity: 0.4;
  }

  .sr-only {
    display: none;
  }

  .small {
    font-size: #{$font-size-sm};
  }

  .xsmall {
    font-size: #{$font-size-xs};
  }

  .large {
    font-size: #{$font-size-lg};
  }

  .hexagon {
    clip-path: #{$hexagon-clip-path};

    img {
      object-fit: cover;
    }
  }

  .hexagon-alt {
    clip-path: #{$hexagon-clip-path_alt};

    img {
      object-fit: cover;
    }
  }

  [style="color: #c2e0f4;"] {
    color: #{$cyan} !important;
  }

  [style="color: #3598db;"] {
    color: #{$blue} !important;
  }

  [style="color: #236fa1;"] {
    color: #{$navy} !important;
  }

  [style="color: #2dc26b;"] {
    color: #{$green} !important;
  }

  .tagline {
    color: #{$green};
    font-weight: #{$font-weight-normal};
  }

  .bg-cyan-gradient {
    background-image: #{$gradient-cyan};
  }

  .bg-green-gradient {
    background-image: #{$gradient-green};
  }

  .mw {
    @each $key, $bp in $grid-breakpoints {
      &-#{$key} {
        max-width: #{$bp};
      }
    }
  }

  [data-sourceflow-path]:not(img):empty {
    display: none;
  }

  // Button

  .btn {
    --bs-btn-font-weight: #{$font-weight-bold};
    white-space: nowrap;
    transition:
      transform 0.15s ease-in-out,
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:hover {
      transform: translateY(-10%);
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: rgba($white, 0.5);
      margin-left: 0.5rem;
    }
  }

  .btn-primary {
    border-color: #{$blue};
    background-color: #{$blue};
    color: #{$white};

    &:hover,
    &:active,
    &:focus {
      color: #{$dark};
      background-color: tint-color($cyan, $btn-hover-bg-tint-amount);
      border-color: tint-color($cyan, $btn-hover-border-tint-amount);
    }
  }

  .btn-gradient-green {
    --bs-btn-border-width: 0;
    background-image: #{$gradient-green};

    &:hover,
    &:focus,
    &:active {
      background-image: #{$gradient-green-270};
    }
  }

  .btn-gradient-cyan {
    --bs-btn-border-width: 0;
    background-image: #{$gradient-cyan};

    &:hover,
    &:focus,
    &:active {
      background-image: #{$gradient-cyan-270};
    }
  }

  .btn-default {
    background-color: #{$cyan};
    border-color: #{$cyan};

    &:hover,
    &:active,
    &:focus {
      background-color: tint-color($cyan, $btn-hover-bg-tint-amount);
      border-color: tint-color($cyan, $btn-hover-border-tint-amount);
    }
  }

  [class*="btn-outline"] {
    --bs-btn-color: #{$dark};
    --bs-btn-bg: #{$white};
  }

  // Theming

  [data-bs-theme="light"] {
    h4 {
      color: #{$blue};
    }

    section {
      padding: 2rem;
      background-color: #{$light};
    }
  }

  [data-bs-theme="dark"] {
    --bs-body-color: #{$white};
    --bs-heading-color: #{$white};
    --bs-link-color: #{$white};
    --bs-link-color-rgb: 255, 255, 255;
    --bs-link-hover-color-rgb: 255, 255, 255;
    --bs-link-hover-color: #{$blue};
  }

  // Typography

  .h3 {
    font-size: calc(#{$h3-font-size} * 0.8);

    @include media-breakpoint-up(md) {
      font-size: #{$h3-font-size};
    }
  }

  a {
    text-decoration: none;
  }

  p {
    iframe {
      max-width: 100%;
    }
  }

  address {
    p {
      margin-bottom: 0;
    }
  }

  .description {
    h4 {
      margin-bottom: 1rem;
    }

    section {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    p {
      a {
        font-weight: 600;
      }
    }

    ul {
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  // Text

  .text-balance {
    text-wrap: balance;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Details
  details {
    border: var(--bs-border-width) solid var(--bs-border-color);
    padding: 1rem 1.5rem;
    margin: 1rem auto;

    @include media-breakpoint-up(md) {
      padding: 2rem 3rem;
      margin: 2rem auto;
    }

    summary {
      font-size: 1.125rem;
      line-height: 1.6;
    }

    &[open] {
      summary {
        margin-bottom: 2rem;
      }
    }
  }
}

//
.js-button.js-reset-search {
  margin: 0;
  background-color: #{$white};
  color: #141821;
  font-weight: bold;
  border-color: #00d5fc;
}

.aos-animate {
  position: relative;
  z-index: 2;
}

//

.error-message {
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5); // 50% black
  padding: 0.5rem;

  .animated-text {
    position: fixed;
    z-index: 1000;
    top: 0;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    display: block; 
    animation: slide 30s linear infinite;

    @include media-breakpoint-down(sm) {
      animation: slide-mobile 30s linear infinite; // Adjusted for mobile
    }
  }
}
.sticky-error {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other elements */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes slide-mobile {
  0% {
    transform: translateX(30%); // Start off-screen to the right
  }
  100% {
    transform: translateX(-100%); // End off-screen to the left
  }
}
